import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Container, Typography, SEO } from '../components';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MUITypography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Copyright } from '../components/Copyright';

const hospital = require('../../static/dreams/st-george-hospital.jpg') as string;
const smartTV = require('../../static/dreams/smarttv.jpg') as string;
const timesSquare = require('../../static/dreams/times-square.jpg') as string;
const printer3d = require('../../static/dreams/3d-printer.webp') as string;
const archMuseum = require('../../static/dreams/arch-museum.jpg') as string;
const cosplay = require('../../static/dreams/cosplay.jpg') as string;
const minicooper = require('../../static/dreams/minicooper.jpg') as string;
const sketchup = require('../../static/dreams/sketchup.jpg') as string;

type DesiresPagePropsType = {
    data: any;
};

export const CustomCardMedia = styled(CardMedia)`
    height: 15rem;
`;

const DesiresPage: FunctionComponent<DesiresPagePropsType> = () => {
    return (
        <Container>
            <SEO
                title="Dreams"
            />
            <br />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CustomCardMedia
                            image={smartTV}
                        />
                        <Copyright
                            prefix="Image source: "
                            sourceText="argos.co.uk"
                            source="https://www.argos.co.uk/product/8839637"
                        />
                        <CardContent>
                            <MUITypography variant="h5" gutterBottom>
                                Купить SmartTV телевизор
                            </MUITypography>
                            <MUITypography variant="body2" color="textSecondary" component="p">
                                Хочу короче такой телевизор, чтобы шпилить в свою плойку иногда
                            </MUITypography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CustomCardMedia
                            image={printer3d}
                        />
                        <Copyright
                            prefix="Image source: "
                            sourceText="eu.snapmaker.com"
                            source="https://eu.snapmaker.com/collections/featured-collection/products/3-in-1-3d-printer"
                        />
                        <CardContent>
                            <MUITypography variant="h5" gutterBottom>
                                Купить 3D printer Snap Maker или похожий
                            </MUITypography>
                            <MUITypography variant="body2" color="textSecondary" component="p">
                                Хочу печатать 3d-модельки зданий и вообще разных штук
                            </MUITypography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CustomCardMedia
                            image={timesSquare}
                        />
                        <Copyright
                            prefix="Image source: "
                            sourceText="deadline.com"
                            source="https://deadline.com/2018/12/how-to-watch-the-times-square-ball-drop-on-new-years-eve-1202527622/"
                        />
                        <CardContent>
                            <MUITypography variant="h5" gutterBottom>
                                Встретить новый год на Times Square
                            </MUITypography>
                            <MUITypography variant="body2" color="textSecondary" component="p">
                                Хочу встретить новый год и рождество в Нью-Йорке, как в "Один дома 2"
                            </MUITypography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CustomCardMedia
                            image={hospital}
                        />
                        <Copyright
                            prefix="Image source: "
                            sourceText="pinterest.de"
                            source="https://www.pinterest.de/pin/216032113361370997/"
                        />
                        <CardContent>
                            <MUITypography variant="h5" gutterBottom>
                                Восстановить фронтоны госпиталя Святого Георга
                            </MUITypography>
                            <MUITypography variant="body2" color="textSecondary" component="p">
                                Калининград заслуживает получить обратно фронтоны на здание госпиталя Святого Георга
                            </MUITypography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CustomCardMedia
                            image={archMuseum}
                        />
                        <Copyright
                            prefix="Image source: "
                            sourceText="mos-holidays.ru"
                            source="https://mos-holidays.ru/muzej-arxitektury-im-a-v-shhuseva/"
                        />
                        <CardContent>
                            <MUITypography variant="h5" gutterBottom>
                                Создать 3d-музей архитектуры Восточной Пруссии
                            </MUITypography>
                            <MUITypography variant="body2" color="textSecondary" component="p">
                                Хочу музей где люди смогут посмотреть на существующие и утраченные здания в режиме AR, а также на разные артефакты, собранные коллегами
                            </MUITypography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CustomCardMedia
                            image={cosplay}
                        />
                        <Copyright
                            prefix="Image source: "
                            sourceText="Sasha Holland"
                            source="https://vk.com/shirogane_sama"
                        />
                        <CardContent>
                            <MUITypography variant="h5" gutterBottom>
                                Посетить крутой косплейный фестиваль
                            </MUITypography>
                            <MUITypography variant="body2" color="textSecondary" component="p">
                                Тряхнуть стариной-молодостью и пойти туда!
                            </MUITypography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CustomCardMedia
                            image={minicooper}
                        />
                        <Copyright
                            prefix="Image source: "
                            sourceText="berlin.mini"
                            source="http://www.berlin.mini/de_DE/home/neuwagen.html"
                        />
                        <CardContent>
                            <MUITypography variant="h5" gutterBottom>
                                Купить Minicooper
                            </MUITypography>
                            <MUITypography variant="body2" color="textSecondary" component="p">
                                Давно мечтал
                            </MUITypography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CustomCardMedia
                            image={sketchup}
                        />
                        <Copyright
                            prefix="Image source: "
                            sourceText="sketchucation.com"
                            source="https://sketchucation.com/forums/viewtopic.php?f=15&t=61445"
                        />
                        <CardContent>
                            <MUITypography variant="h5" gutterBottom>
                                Купить лицуху Sketchup + Vray
                            </MUITypography>
                            <MUITypography variant="body2" color="textSecondary" component="p">
                                Давно мечтал домики поделать
                            </MUITypography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <br />
        </Container>
    );
};

// export const pageQuery = graphql`
//     query HomePageQuery {
//     }
// `;

export default DesiresPage;
